import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import AboutImage from "../../Assets/images/mainAboutPage.png";
import "./AboutUs.css";
import { Link } from 'react-router-dom';

function AboutUs() {
  const [firstRender, setFirstRender] = useState(true);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    setFirstRender(false);

    const handleScroll = () => {
      setScrollY(window.scrollY);
      
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
<div className=''>

<div id='aboutUs' className={`sm:flex justify-center sm:w-full sm:h-full ${firstRender ? 'first-render' : ''} `}>
      <div className="sm:flex justify-center sm:mx-[149px]   sm:mt-[136px] p-0">
        <div className="sm:flex-1 sm:justify-center sm:text-start sm:mr-[40px] font-urbanist">
          <div className={`text-start ml-[16px] mt-[33px] sm:mt-0 sm:mb-[24px] ${scrollY > 0 ? 'scroll-animate' : ''}`}>
            <h1 className='font-semibold sm:text-[81px] text-[40px] sm:translate-y-[59px]'>Who we</h1>
            <h1 className='font-thin sm:text-[81px] text-[30px]  text-[#9c9a9a] sm:translate-y-[24px]'>are?</h1>
          </div>
          <div className='m-[10px] pb-[48px] sm:hidden block'>
            <img src={AboutImage} alt="About image" className='about sm:w-[484px] sm:h-[431px] sm:hidden block' />
          </div>
          <motion.div
            className={`sm:text-[16px] sm:leading-[21px] text-[#000000] leading-[21px] sm:mt-0 sm:tracking-normal font-medium  text-justify ${scrollY > 0 ? 'scroll-animate' : ''}`}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.9 }}
            viewport={{ once: false, amount: 0.5 }}
          >
            <motion.p className='mx-[10px] sm:leading-6'>
            Solwyz Technologies stands at the forefront of modern technology solutions, specializing in pharmacy e-commerce and pharmaceutical ERP systems. Our innovative approach ensures that businesses in the pharmaceutical sector can seamlessly integrate advanced digital solutions to enhance their operational efficiency and customer engagement. We are committed to delivering tailored services that meet the unique needs of our clients, empowering them to thrive in a competitive marketplace.
            </motion.p>
            <motion.p className='sm:mt-[35px] mt-[25px] mx-[10px] sm:leading-6' 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.9 }}
              viewport={{ once: false, amount: 0.5 }}
              >
              In addition to our core offerings, Solwyz Technologies excels in custom mobile application development, providing businesses with the tools they need to connect with their customers effectively. Our comprehensive digital marketing services are designed to cater to a wide range of industries, ensuring that our clients can maximize their revenue potential. By leveraging cutting-edge technology and strategic marketing insights, we help businesses achieve their goals and drive sustainable growth.
            </motion.p>
            <Link to='/aboutdetails'>
            <motion.button  className="sm:flex flex sm:justify-around mx-auto sm:ml-0 sm:mb-[76px] mb-[36px] mt-[24px] justify-center bg-banner-buttonBackground text-white hover:text-gray-300 font-bold py-2 px-4 rounded-full sm:w-[248px] w-[165px] sm:h-[66px] h-[48px]  sm:mt-[24px] "
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.9 }}
              viewport={{ once: false, amount: 0.5 }}
              >
                    <div className='w-fit my-auto sm:text-[18px] text-[16px] font-medium '>Know More</div>
                    <div className='w-fit my-auto '>
                        
                        <button type="button" className="text-banner-buttonBackground  bg-white hover:bg-gray-300 font-medium rounded-full text-sm p-2.5 text-center sm:translate-y-0 translate-y-[-4px]  translate-x-[18px] sm:translate-x-[29px]">
                            <svg className="sm:w-7 w-[21px] sm:h-7 h-[20px]" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </button>
                    </div>
                </motion.button>
                </Link>
          </motion.div>
        </div>
        
        <div className='m-[10px] sm:mt-[132px]'>
          <img src={AboutImage} alt="About image" className='about sm:w-[508px] sm:h-[455px] hidden sm:block sm:translate-y-[25px]' />
        </div>
      </div>
        
    </div>

    </div>
  );
}

export default AboutUs;
