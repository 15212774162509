import React, { useEffect } from 'react'
import About_Detail_Image from "../../Assets/images/mainAboutPage.png";
import About_Detail_Image2 from "../../Assets/images/about2.png"
import { Link } from 'react-router-dom';

function About_Detail_Page() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <div>

      <div className='sm:text-[16px] text-[10px] font-urbanist font-medium justify-center text-center pt-[100px] '>

       <Link to='/'> <span className='text-gray-400'>HOMEPAGE / </span> </Link><span>ABOUT</span>


      </div>
      <div className='sm:flex sm:justify-center sm:w-full sm:h-full'>
        <div className="sm:flex sm:justify-center sm:w-[80%] sm:h-screen sm:pt-[141px] pt-[25px] sm:pb-[140px] sm:my-0 p-0">
          <div className="sm:flex-1 sm:justify-center sm:text-justify sm:mr-[84px] sm:my-0 font-urbanist sm:translate-y-[-72px]">
            <div className='sm:pt-1 sm:mt-0 text-start mx-[16px]'>
              <h1 className='font-semibold sm:text-[81px] text-[40px]'>Who we</h1>
              <h1 className='font-thin sm:text-[81px] text-[30px] sm:translate-y-[-2.25rem] '>are?</h1>
            </div>
            <div  >
            <img src={About_Detail_Image}
              alt="About image"
              className=' sm:hidden block sm:w-[484px] sm:h-[431px] sm:border-l-4  sm:font-sans sm:font-bold border-black border-opacity-[23%] sm:mt-[156px] p-[12px]'
            />

          </div>
            <div className='sm:py-[31px] sm:text-[17px] opacity-[70%] font-medium sm:translate-y-[-114px] sm:mt-[73px] p-[16px] text-justify'>
              <p className='py-4'

              >Solwyz Technologies stands at the forefront of modern technology solutions, specializing in pharmacy e-commerce and pharmaceutical ERP systems. Our innovative approach is designed to streamline operations for pharmacies, enabling them to effectively manage their inventory, sales, and customer relationships through a robust online platform. By integrating cutting-edge technology with industry-specific knowledge, we empower pharmacies to enhance their  online presence, improve customer engagement, and ultimately drive sales growth in an increasingly competitive market.</p>
              <p>
              In addition to our pharmacy-focused solutions, Solwyz Technologies excels in custom mobile application development tailored to meet the unique needs of various businesses. Our team of experienced developers collaborates closely with clients to create user-friendly applications that not only enhance operational efficiency but also provide a seamless experience for end-users. Whether it is a mobile app for managing pharmacy orders or a platform for customer interaction, our solutions are designed to be scalable and adaptable, ensuring that they evolve alongside your business.</p>
            </div>
          </div>
          <div  >
            <img src={About_Detail_Image}
              alt="About image"
              className='hidden sm:block sm:w-[484px] sm:h-[431px]   sm:font-sans sm:font-bold border-black border-opacity-[23%] sm:translate-y-[-35px] sm:mt-[156px] p-[12px]'
            />

          </div>
        </div>


      </div>
      <div className='sm:flex sm:justify-center sm:w-full sm:h-full'>
        <div className="sm:flex sm:justify-center sm:w-[80%] sm:h-[73vh] sm:pt-[141px] sm:my-0 p-0">
          <div  >
            <img src={About_Detail_Image2}
              alt="About image"
              className=' sm:w-[593px] sm:h-[428px] sm:translate-y-[-129px]   sm:font-sans sm:font-bold border-black sm:border-opacity-[23%] p-[12px] '
            />

          </div>
          <div className="sm:flex-1 sm:justify-center sm:ml-[6px] sm:my-0 font-urbanist sm:text-justify p-[16px] ">

            <div className='sm:py-[31px] sm:text-[17px] opacity-[70%] font-medium sm:mt-0 sm:translate-y-[-155px] text-justify'>
              <p className='sm:py-4'

              >Furthermore, our digital marketing services are meticulously crafted to cater to a diverse range of industries, ensuring that businesses can maximize their revenue potential. We employ a comprehensive strategy that includes search engine optimization, social media marketing, and targeted advertising campaigns, all aimed at increasing visibility and driving traffic to your online platforms. By leveraging data analytics and market insights, Solwyz Technologies helps businesses identify opportunities for growth and implement effective marketing strategies that resonate with their target audience, ultimately leading to increased sales and brand loyalty.</p>
              <p>
                </p>
            </div>
          </div>

        </div>


      </div>

    </div>

  );
}



export default About_Detail_Page