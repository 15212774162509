import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    name: "Krunal Kishan",
    title: "Chairman BSGM Group",
    quote: "Working with Solwyz Technologies has been an exceptional experience. Their team provided us with top-notch e-commerce solutions tailored to our business needs. From seamless integration to user-friendly features, they've helped streamline our operations and enhance our online presence. Their support is responsive, and their expertise in the e-commerce space truly sets them apart. I highly recommend Solwyz Technologies for any business looking to grow their online sales and improve customer engagement!",
  },
  {
    name: "Arvind Reddy",
    title: "AVM Group",
    quote: "Solwyz Technologies transformed our online presence with their outstanding digital marketing services. Their expertise in SEO, social media marketing, and targeted campaigns significantly increased our website traffic and customer engagement. The team is knowledgeable, proactive, and always delivers measurable results. Thanks to Solwyz, we've seen a noticeable boost in our online visibility and overall business growth. I highly recommend their digital marketing services for any company looking to thrive in the digital space!"
    
,
  },
  {
    name: "Shardul Sharma",
    title: "Chairman Querz",
    quote: "Solwyz Technologies has revolutionized our operations with their Pharma ERP solution. The system is intuitive, comprehensive, and perfectly tailored to meet the unique demands of our industry. Their team's expertise in integrating our processes has led to increased efficiency, real-time data access, and streamlined workflows across departments. The impact on our productivity and compliance management has been extraordinary. I highly recommend Solwyz Technologies to any pharmaceutical business seeking a reliable and innovative ERP partner.",
  },
  {
    name: "Sachin Dhawan",
    title: "ADTA Tech",
    quote: "Solwyz Technologies delivered a custom mobile application that exceeded our expectations. It's intuitive, feature-rich, and perfectly suited to our business needs. Their team's expertise and attention to detail made the entire process seamless. Highly recommended for any business seeking a tailored mobile solution",
  }
  // Add more testimonials as needed
];

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={``}
      style={{ ...style, display: "block", right: "25px" ,  zIndex: 1 }}
      onClick={onClick}
    >
      
      <button type="button" className="hidden md:block absolute top-48 right-[15rem] text-banner-buttonBackground bg-gray-300 hover:bg-gray-500 font-medium rounded-full text-sm p-2.5 text-center">
                <svg class="w-5 h-5" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
      </button>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={``}
      style={{ ...style, display: "block", left: "25px", zIndex: 10 }}
      onClick={onClick}
    >
      <button type="button" className="hidden md:block absolute z-50 top-48 left-[15rem] text-banner-buttonBackground rotate-180 bg-gray-300 hover:bg-gray-500 font-medium rounded-full text-sm p-2.5 text-center">
                <svg class="w-5 h-5" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
      </button>
    </div>
  );
};

const CarouselComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="mx-auto mt-2">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="max-w-3xl text-center p-8">
            
            <h3 className="text-[25px] font-bold">{testimonial.name}</h3>
            <p className="text-[15px] text-gray-600">{testimonial.title}</p>
            <p className="text-[18px] font-normal mb-4 mt-16 sm:leading-8">{testimonial.quote}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselComponent;
