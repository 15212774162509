import Header from './Components/Header/Header';
import './App.css'
import Banner from './Components/Banner/Banner';
import AboutUs from './Components/AboutUs/AboutUs';
import CustomerVoices from './Components/CustomerVoices/CustomerVoices';
import ContactForm from './Components/ContactForm/ContactForm';
import Services from './Components/Services/Services';
import Blogs from './Components/Blogs/Blogs';
import BlogDetails1 from './Components/BlogDetails/BlogDetails1';
import BlogDetails2 from './Components/BlogDetails/BlogDetails2';
import Footer from './Components/Footer/Footer';
import SpeedDialComponent from './Components/SpeedDial/SpeedDial';
import AppsMenu from './Components/ToggleMenu/ToggleMenu';
import About_Detail_Page from './Components/AboutDetails/AboutDetails';
import GoogleMap from './Components/GoogleMap/GoogleMap';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import PharmaECommerce from './Components/ServiceDetails/PharmaECommerce';
import PharmaERP from './Components/ServiceDetails/PharmaERP';
import CustomizedMobileApp from './Components/ServiceDetails/CustomizedMobileApp';
import Customer_deatil from './Components/ServiceDetails/DigitalMarketing';

import CareerDetails from './Components/CareerDeatils/CareerDetails';

import Careers from './Components/Careers/Careers';
import CareerDetailForm from './Components/CareerDetailForm/CareerDetailForm';
import DigitalMarketing from './Components/ServiceDetails/DigitalMarketing';



function App() {
  return (
    <div className="App">
      <div >
        <Router>
        <Navbar />
          <Routes>
            <Route path='/' exact Component={Home} />
            <Route path='/aboutus' Component={AboutUs} />
            <Route path='/aboutdetails' Component={About_Detail_Page} />
            <Route path='/blogs' Component={Blogs}/>
            <Route path='/contactus' Component={ContactForm} />
            <Route path='/blogdetails1' Component={BlogDetails1} />
            <Route path='/blogdetails2' Component={BlogDetails2} />
            <Route path='/services' Component={Services} />
            <Route path='/services/pharmaecommerce' Component={PharmaECommerce} />
            <Route path='/pharmaecommerce' Component={PharmaECommerce} />
            <Route path='/services/pharmaerp' Component={PharmaERP} />
            <Route path='/pharmaerp' Component={PharmaERP} />
            <Route path='/services/customizedmobileapp' Component={CustomizedMobileApp} />
            <Route path='/customizedmobileapp' Component={CustomizedMobileApp} />
            <Route path='/services/digitalmarketing' Component={DigitalMarketing} />
            <Route path='/digitalmarketing' Component={DigitalMarketing} />

            <Route path='/careers/careerdetails' Component={CareerDetails} />
            <Route path='/careers/careersApplication' Component={CareerDetailForm} />
            <Route path='/careers/careerdetails/careersApplication' Component={CareerDetailForm} />

            <Route path='/careers' Component={Careers} />
          </Routes>
          <Footer />
        </Router>
        {/* <Home/> */}
        
        <SpeedDialComponent />


      </div>
    </div>
  );
}

export default App;
