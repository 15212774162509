import React, { useEffect } from 'react'
import market_image from '../../Assets/images/customized2.png'
import market_img2 from '../../Assets/images/customized1.png'
import { Link } from 'react-router-dom';


function CustomizedMobileApp() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (


        <div>
            <div className='sm:text-[16px] text-[10px] font-urbanist font-medium justify-center text-center sm:pt-[153px] pt-[100px] mx-auto '>
                <Link to='/'><span className='text-gray-400'>HOMEPAGE / </span> <Link to='/services'><span className='text-gray-400'>SERVICES / </span></Link> </Link> <span className='uppercase'>CustomizeD mobile App</span>
            </div>
            <div className='sm:flex sm:justify-center sm:w-full sm:h-full sm:mx-0 mx-[16px]'>
                <div className="sm:flex sm:justify-center sm:w-[80%] sm:pt-0  pt-[30px]  sm:my-0 p-0">
                    <div className="sm:flex-1 sm:justify-center sm:text-justify sm:mr-[84px] sm:my-0 font-urbanist  text-justify">
                        <div className='  sm:h-[70px]  '>
                            <h1 className='font-normal sm:mt-[104px] sm:text-[56px] text-[32px]'>Customized Mobile App</h1>
                            {/* <h1 className='font-thin sm:text-[60px] text-[30px] sm:translate-y-[-2.25rem] '>via social media </h1> */}
                        </div>
                        <div className='sm:hidden block' >
                            <img src={market_img2}
                                alt="About image"
                                className=' sm:w-[361px] sm:h-[236.41pxpx] mt-8   sm:font-sans sm:font-bold sm:mt-[156px] '
                            />

                        </div>
                        <div className=' sm:text-[17px] opacity-[70%] font-medium sm:pt-0 pt-8  sm:mt-[48px]'>
                            <p className=''

                            > Our customized mobile app development services provide a comprehensive solution designed to meet the specific needs of businesses. We specialize in creating tailor-made mobile applications that seamlessly integrate with your existing systems and align with your strategic goals. With extensive expertise, our team develops apps that are not only user-friendly but also effectively engage users through culturally relevant and intuitive interfaces. Whether you're a startup or a large enterprise, our solutions are scalable and adaptable to support your growth and evolving requirements.</p>

                        </div>
                    </div>
                    <div className='hidden sm:block' >
                        <img src={market_img2}
                            alt="About image"
                            className=' sm:w-[565px] sm:h-[370px] sm:mt-[104px]   sm:font-sans sm:font-bold  sm:px-0 px-[16px]'
                        />

                    </div>
                </div>


            </div>
            <div className='sm:flex sm:justify-center sm:w-full sm:h-full mx-auto sm:mt-0 mt-8 '>
                <div className="sm:flex sm:justify-center sm:w-[80%] sm:mt-[104px]  sm:my-0 ">
                 
                        <img src={market_image}
                            alt="About image"
                            className=' sm:w-[565px] sm:h-[370px] w-[430px] h-[236px]   sm:font-sans sm:font-bold sm:px-0 px-[16px] '
                        />

                 
                    <div className="sm:flex-1 sm:justify-center sm:ml-[84px]  font-urbanist sm:text-justify sm:px-0 px-[16px] text-justify opacity-[70%] font-medium ">

                        <div className=' sm:text-[17px] sm:mt-0 mt-8 '>

                            <p>
                            Our mobile apps are optimized for performance, ensuring fast load times and smooth operation. Designed with SEO-friendly features, they enhance visibility and drive user engagement, helping your app stand out in a competitive market. We incorporate the latest market trends and user preferences to deliver applications that support your business objectives and improve operational efficiency. Additionally, our services include robust security measures, seamless system integration, and ongoing support to ensure that your app continues to run smoothly, providing a competitive edge and a high-quality user experience in your industry.</p>
                        </div>
                    </div>

                </div>


            </div>
            <div className='sm:w-[944px] sm:h-[432px] mx-[16px] sm:mx-auto bg-[#F7F7F7] sm:mb-[72px] sm:mt-[104px]  my-[72px]'>
                <div className='sm:w-[712px] sm:h-[312px] py-[40px] mx-auto '>
                    <h1 className='sm:text-[72px] text-[30px] font-semibold tracking-[3%] sm:tracking-tight text-center sm:pt-[60px] leading-[42px] sm:leading-[100px]'>Lets quote a <span className='font-light'> business </span>
                        <span className='font-thin '>today </span></h1>
                    <Link to='/contactus'>
                        <button id='bannerButton' className="sm:flex flex sm:justify-around justify-center sm:m-auto bg-banner-buttonBackground text-white hover:text-gray-300 font-bold py-2 px-4 rounded-full sm:w-[261px] w-[223px] sm:h-[56px] h-[48px] sm:mt-[76px] mb-[24px] mx-auto">
                            <div className='w-fit my-auto sm:text-[18px] text-[14px] font-medium ml-auto'>Get an estiamte</div>
                            <div className='w-fit my-auto ml-auto'>
                                <button type="button" className="text-banner-buttonBackground bg-white hover:bg-gray-300 font-medium rounded-full text-sm p-2.5 text-center sm:translate-y-[-4px] translate-y-[-4px] translate-x-[10px]">
                                    <svg className="sm:w-7 w-[21px] sm:h-7 h-[20px]" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                    </svg>
                                </button>
                            </div>
                        </button>
                    </Link>
                </div>

            </div>

        </div>

    );
}




export default CustomizedMobileApp